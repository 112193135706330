import React from "react";
import Layout from "../../Layout";
import "./style.scss";

export default function SubjectsPage() {
  return (
    <Layout>
      <div className="subjects">
        <h1 className="heading">Subjects</h1>
        <p>
          Chinmaya Vidyalaya offers the following class-wise subjects. Subject
          allocation in Class XI and XII is decided based on performance and
          aptitude, in conjunction with preference. Final subject allocation is
          at the discretion of the Management.
        </p>

        <div className="subjects__table">
          <div className="subjects__table__row subjects__table__row--heading">
            <div className="subjects__table__col1">Class</div>
            <div className="subjects__table__col2">Subjects</div>
          </div>

          <div className="subjects__table__row">
            <div className="subjects__table__col1">Pre-Primary</div>
            <div className="subjects__table__col2">
              Mathematics, Hindi, English, Art, Music, Dance
            </div>
          </div>

          <div className="subjects__table__row">
            <div className="subjects__table__col1">I-II</div>
            <div className="subjects__table__col2">
              English, Hindi, Mathematics, Environmental Studies, Computer
              Science, Art, Dance, Music, General Knowledge, Health and Physical
              Education, Value Education and Happiness Curriculum
            </div>
          </div>
          <div className="subjects__table__row">
            <div className="subjects__table__col1">III-V</div>
            <div className="subjects__table__col2">
              English, Hindi, Mathematics, Environmental Studies, Computer
              Science, Sanskrit (Class-V), Art, Dance, Music, General Knowledge,
              Health and Physical Education, Value Education and Happiness
              Curriculum
            </div>
          </div>
          <div className="subjects__table__row">
            <div className="subjects__table__col1">VI-VIII</div>
            <div className="subjects__table__col2">
              English, Hindi, Mathematics, Science, Computer Science, Value
              Education, Social Science, Sanskrit, General Awareness, Visual
              Art, Performing Art, Work Education, Health and Physical
              Education, Value Education and Happiness Curriculum
            </div>
          </div>
          <div className="subjects__table__row">
            <div className="subjects__table__col1">IX-X</div>
            <div className="subjects__table__col2">
              English, Hindi, Mathematics, Science, Artificial Intelligence,
              Value Education, Social Science, Sanskrit, Visual Art, Performing
              Art, Work Education, Health and Physical Education, Value
              Education and Happiness Curriculum
            </div>
          </div>

          <div className="subjects__table__row">
            <div className="subjects__table__col1">XI-XII</div>
            <div className="subjects__table__col2">
              English, Mathematics, Physics, Chemistry, Biology, Accountancy,
              Business Studies, Computer Science, Informatics Practices,
              Psychology, Economics, Painting, Entrepreneurship, Political
              Science, Sanskrit, Hindustani Music, Value Education, Physical
              Education
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
